.drawer-header {
  display: flex;
  flex-direction: column;
  position: relative;

  .header-title {
    order: 2;
    padding-bottom: 2%;
  }

  .steps-wrapper {
    order: 3;
  }

  .close-button-wrapper {
    order: 1;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.drawer-steps {
  .ant-steps-item-finish {
    .ant-steps-item-container {
      .ant-steps-item-icon {
        background-color: inherit;
        border-color: #f96302;
        .ant-steps-icon {
          .anticon {
            color: inherit;
          }
        }
      }
    }
  }
}

.order-status-history-section {
  &-blue {
    border-top: 1px #2081c9 solid;
    border-left: 13px #2081c9 solid;
    border-bottom: 1px #2081c9 solid;
    border-right: 1px #2081c9 solid;
    padding: 20px;
  }

  &-yellow {
    border-top: 1px #ffaa00 solid;
    border-left: 13px #ffaa00 solid;
    border-bottom: 1px #ffaa00 solid;
    border-right: 1px #ffaa00 solid;
    padding: 20px;
  }

  &-red {
    border-top: 1px #ed1c24 solid;
    border-left: 13px #ed1c24 solid;
    border-bottom: 1px #ed1c24 solid;
    border-right: 1px #ed1c24 solid;
    padding: 20px;
  }

  &-green {
    border-top: 1px #00ae4d solid;
    border-left: 13px #00ae4d solid;
    border-bottom: 1px #00ae4d solid;
    border-right: 1px #00ae4d solid;
    padding: 20px;
  }

  &-gray {
    border-top: 1px #939393 solid;
    border-left: 13px #939393 solid;
    border-bottom: 1px #939393 solid;
    border-right: 1px #939393 solid;
    padding: 20px;
  }
}

@media screen and (max-width: 1018px) {
  .drawer-steps-container {
    /* Display Steps vertically for small screens */
    display: block;
  }
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;