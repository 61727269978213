.location-dropdown-container {
  display: flex;
  align-items: center;

  .location-text {
    color: #696969;
  }

  .select-wrapper {
    .ant-select {
      font-size: 16px;
    }

    .ant-select-selection-search-input {
      color: white !important;
    }

    .ant-select-single .ant-select-selection-item {
      font-size: 16px;
      color: white !important;
    }

    .ant-select-arrow {
      color: white !important;
    }
  }
}

.dropdown-wrapper {
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: black !important;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: black !important;
  }

  .option-container {
    color: white !important;
  }

  .ant-empty-description {
    color: white !important;
  }
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;