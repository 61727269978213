@import "~antd/dist/antd.less";

#search-container {
  position: fixed;
  width: 100%;
  background-color: white;
  z-index: 3;
  padding: 20px 20px 25px 40px;
  top: 112px;
  left: 0;
  border-bottom: 2px solid #d3d3d3;

  input {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
  }

  label {
    font-size: 14px;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    opacity: 1;
    color: black;
  }

  .search-group {
    width: 100%;

    .ant-select {
      width: 35%;
    }

    .ant-select-selection-placeholder {
      font-size: 16px;
      font-weight: 500;
      color: #666666;
      opacity: 1;
    }

    .ant-input-group.ant-input-group-compact {
      display: flex;
      margin-right: 20px;

      .search-form {
        width: 100%;
        display: flex;
        align-items: center;
      }
    }

    .ant-input {
      &:focus {
        box-shadow: none;
      }
    }

    .ant-select:not(.ant-select-disabled):hover {
      .ant-select-selector {
        border-color: #666666;
        border-right-width: 0;
      }
    }

    .ant-select-focused:not(.ant-select-disabled) {
      .ant-select:not(.ant-select-customize-input) {
        .ant-select-selector {
          box-shadow: none;
        }
      }
    }

    .ant-select:not(.ant-select-customize-input) {
      .ant-select-selector {
        border: 1px solid #666666;
      }
    }

    .ant-input:focus {
      .ant-input-focused {
        box-shadow: none;
      }
    }
  }

  .ant-select-arrow {
    width: 20px;
    font-size: 16px;
    top: 55%;
    color: @primary-color;
  }

  .search-input {
    width: 60%;
    border: 1px solid #666666;
    border-right: none;

    &:hover {
      border-right: none;
    }
  }

  .anticon-redo {
    font-size: 20px;
  }

  #clear-search-button {
    margin-top: 24px;
    color: @primary-color;
    padding: 8px 0;
    font-weight: 600;
    width: 145px;
  }

  .ant-btn[disabled] {
    background-color: transparent;
    color: #666666;
    font-size: 14px;
  }

  .search-btn {
    font-size: 14px;
    border-left: none;
    border-top: 1px solid #666666;
    border-bottom: 1px solid #666666;
    border-right: 1px solid #666666;
    flex: 0.3;
    margin-right: 8px;
  }

  .first-input {
    width: 28%;
    border-radius: 0;
    border: 1px solid #666666;
    border-right: 1px solid #eaeaea;
    flex: 1;
  }

  .last-input {
    width: 28%;
    border-radius: 0;
    border: 1px solid #666666;
    border-left: 1px solid #eaeaea;
    border-right: none;
    flex: 1;
  }

  #refresh-icon {
    height: 18px;
    padding-right: 5px;
  }
}

.search-dropdown {
  .ant-select-item-option {
    font-size: 14px;
    font-weight: 400;

    &.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: #f5f5f5;
      font-weight: 400;
    }
  }
}

@media (max-width: 1199px) {
  #search-container {
    padding: 20px 20px;

    .search-input {
      width: 58%;
    }

    .search-btn {
      flex: 0.5;
      margin-right: 8px;
      width: 18%;
    }
  }
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;