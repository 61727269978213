#pod-modal {
  overflow-y: auto;
  max-height: 700px;
  padding: 20px;
  .pod-modal-header-label {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
  }
  .pod-modal-signature {
    text-align: center;
    height: auto;
    width: 200px;
    display: block;
    margin: 15px auto;
  }
  .pod-modal-image {
    height: auto;
    width: 100%;
    display: block;
    margin: 15px auto;
  }
  .pod-images-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .pod-image-wrapper {
      margin-bottom: 10px;
    }
  }
}
.attachment-not-found-text {
  display: flex;
  justify-content: center;
  font-family: "Open Sans";
  font-weight: bolder;
  font-size: x-large;
}

.pod-spinner {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ant-image-preview-img {
  height: 800px !important;
  width: 800px !important;
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;