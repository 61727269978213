@import "../../App.less";

.delivery-window-container {
  position: relative;
  padding: 0 15px 0 15px;
  height: 150px;
  .delivery-status {
    font-size: 11px;
    margin-bottom: 6px;
    color: #595959;
  }
  .time-window {
    color: #000000;
    font-size: 16px;
  }
  .cancelled-status {
    font-size: 16px;
    color: #595959;
  }
  br {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
  }
  #cancel-call {
    width: 25px;
    height: 18px;
    position: absolute;
    left: 150px;
    cursor: pointer;
  }
  #reset-time {
    width: 25px;
    height: 18px;
    position: absolute;
    left: 175px;
    cursor: pointer;
  }
  .change-time-window {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    color: #f96302;
  }
  .change-time-window > .modify {
    margin-right: 20px;
  }
}

@media (max-width: 1199px) {
  .delivery-window-container {
    text-align: center;
    border-bottom: 1px solid #cccccc;
    padding: 0;
    margin: 0 18px;
    height: 175px;
  }
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;