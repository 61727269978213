.purchase-order-details {
  padding-left: 30px;
  padding-bottom: 24px;

  .product-description {
    font-weight: 600;
    font-size: 14px;
  }

  .product-details {
    padding-top: 20px;
    font-size: 12px;

    .item-info {
      padding-bottom: 5px;
    }

    .parts-service-info {
      padding-top: 5px;

      .parts-service-header {
        font-weight: 700;
      }
    }
  }
}

.vendor-name {
  font-weight: 600;
  font-size: 16px;
  margin-top: 5px;
}

.vendor-detail {
  padding-left: 30px;
  font-size: 16px;
}

.product-top-divider {
  margin-top: 0px;
  margin-bottom: 2px;
  border-top: 2px solid rgba(0, 0, 0, 0.06);
}

.product-bottom-divider {
  margin-top: 2px;
}

.ant-image-preview-img {
  height: 600px;
  width: 600px;
}

.cancelled-item-section {
  border: 1px solid #ed1c24;
  padding-top: 20px;
  margin-bottom: 20px;
  background: #f9eae1;
}

.strike-cancelled-content {
  text-decoration: line-through;
}

#cancelled-pill {
  text-align: center;
  border-radius: 25px;
  background-color: #ed1c24;
  height: 16px;
  margin-bottom: 10px;
  width: 100%;

  .cancelled-text {
    font-weight: bold;
    font-size: 10px;
    color: white;
    position: relative;
    top: -5px;
  }
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;