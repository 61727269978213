// Ant Design Form
.mo-form {
  display: contents;
  width: 100%;
  grid-column: 1/13;

  .ant-form {
    font-size: inherit;
    line-height: inherit;
  }

  .ant-form-item {
    font-size: inherit;
    line-height: inherit;
  }
}

.manual-order-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  z-index: 1;
}

// Manual Order Stepper
#mo-stepper {
  display: flex;
  justify-content: center;
  .ant-steps-item-content {
    width: 0;
  }
  .ant-steps-item-icon {
    width: 12px;
    height: 12px;
  }
  .ant-steps-item-tail {
    &::after {
      width: 0;
      height: 0;
      margin: 0;
    }
  }
  .ant-steps-item-icon {
    width: 10px;
    height: 10px;
    margin-left: 20px;
    .ant-steps-icon {
      top: 10px;
    }
  }
  .ant-steps-item {
    flex: 0;
    &:first-child {
      .ant-steps-icon-dot {
        left: 18px;
      }
    }
  }
  .ant-steps-item-finish {
    .ant-steps-item-icon {
      .ant-steps-icon {
        .ant-steps-icon-dot {
          background: rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}

.manual-order-header {
  padding-bottom: 30px;
}

.manual-order-selector {
  label {
    font-weight: bold;
    font-size: 14px;
    padding: 0;
  }

  .revisit-reason-wrapper {
    border: 1px solid lightgray;
    display: flex;
    justify-content: center;
    align-items: center;

    .revisit-reason {
      margin: 0;
      font-weight: bold;
    }
  }
}

.product-orders-container {
  height: 240px;
  overflow: scroll;
  .ant-checkbox-group {
    display: inherit;
  }
}

.line-items-header {
  font-weight: 600;
  .vendor-limit {
    font-weight: 300;
  }
}

.vendor-container {
  margin-top: 15px;
  .vendor {
    color: #303030;
    font-size: 16px;
    font-weight: 300;
  }
  .vendor-number {
    font-weight: 600;
  }
}

.line-items-container {
  background-color: #f2f2f2;
  margin-top: 10px;
  .ant-checkbox-group {
    width: 100%;
  }
  .line-items {
    padding: 5px 10px;
    width: 190px;
    margin: 0 auto;
    span {
      .model-nbr {
        font-weight: 600;
      }
    }
    .ant-checkbox-wrapper {
      display: flex;
      padding: 5px;
    }

    .ant-checkbox {
      top: 0.91em;
    }

    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin: 0;
    }

    .ant-checkbox-checked::after {
      border: none;
    }
  }
}

.mo-calendar-container {
  height: 300px;
  margin: 0 25px 0 0;
  padding-top: 30px;
  border-left: 1px solid #cccccc;
}

.actions-container-mo {
  position: relative;
  height: 100%;
  padding: 50px 18px 0 18px;
  border-left: 1px solid #cccccc;
  #MOPrevious {
    margin-bottom: 20px;
  }
}

.cancel-btn {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 30px;
  width: 100%;
}

.ant-btn-link {
  width: 100%;
  color: #f96302;
  &:hover {
    color: #f96302;
  }
}

.ant-btn-lg {
  width: 100%;
}

.notes {
  margin: 0;
  label {
    font-size: 18px;
  }
}

.revisit-details-wrapper {
  padding: 0 18px;
  .revisit-window {
    .manual-order-details-header {
      font-size: 17px;
      color: black;
      font-weight: 600;
      margin-bottom: 12px;

      span {
        text-align: right;
      }
    }

    .manual-order-details-selector {
      border-bottom: 1px solid #dddddd;
      margin-bottom: 10px;

      p {
        font-size: 14px;
        margin: 0;
      }

      .manual-order-models {
        font-weight: 300;
        margin-bottom: 10px;

        span {
          font-weight: 600;
        }
      }

      .manual-order-revisit {
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .product-orders-container {
    height: 217px;
  }

  .revisit-details-wrapper {
    padding: 0;
    margin: 0 18px;
    border-bottom: 1px solid #cccccc;
    height: 188px;
    .revisit-window {
      height: 166px;
      overflow-y: scroll;
      margin-bottom: 20px;
      .manual-order-details-header {
        text-align: center;
      }
      .manual-order-details-selector {
        text-align: center;
      }
    }
  }
}

@media (max-width: 991px) {
  .product-orders-container {
    height: 145px;
  }

  .revisit-details-wrapper {
    padding: 0;
    margin: 0 18px;
    border-bottom: 1px solid #cccccc;
    height: 250px;
    .revisit-window {
      height: 225px;
      overflow-y: scroll;
      margin-bottom: 20px;
      .manual-order-details-header {
        text-align: center;
      }
      .manual-order-details-selector {
        text-align: center;
      }
    }
  }

  .mo-calendar-container {
    height: 370px;
    border-left: none;
  }

  .mo-actions-container {
    .cancel-btn {
      position: relative;
      margin-top: 0;
      bottom: 0;
    }
  }

  .actions-container-mo {
    border-left: none;
    border-top: 1px solid #cccccc;
    margin: 0 18px;
    padding: 35px 18px 25px 18px;
    #MOPrevious {
      margin-bottom: 22px;
    }
    .cancel-btn {
      position: relative;
      margin-top: 15px;
      bottom: 0;
    }
  }
}

.manual-order-loading {
  position: relative;
  top: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;