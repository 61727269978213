@import "~antd/dist/antd.less";

.ant-table-thead > tr > th {
  white-space: nowrap;
}

.ant-table-tbody > tr > th {
  white-space: pre;
}

.routedetail-view-table {
  background-color: white;
  margin-top: 14px;
  padding: 20px;

  .routedetail-view-table-header {
    display: flex;
    align-items: flex-end;
    vertical-align: bottom;
    h3 {
      margin-left: 8px;
      font-weight: bold;
      margin-right: 15px;
      margin-bottom: 8px;
    }
    h4 {
      margin-bottom: 8px;
      color: rgb(194, 190, 190);
    }
    .routedetail-view-table-header-back-arrow {
      font-size: 16px;
      margin-right: 5px;
      margin-bottom: 8px;
    }
    .routedetail-view-download-manifest-button {
      margin-left: auto;
      border-style: solid;
      border-color: orange;
    }
  }

  .routedetail-view-table-content {
    padding-top: 15px;
  }

  .routedetail-view-table-query-driverinfo-table {
    border: 1px solid rgb(225, 225, 225);
    margin-bottom: 10px;
    height: 8px;
    border-collapse: collapse;
  }
  .routedetail-view-table-row {
    text-align: left;
    border: 1px solid rgb(225, 225, 225);
    height: 30px;
    background-color: rgb(250, 250, 250);
  }
  .routedetail-view-table-column-left {
    padding: 8px;
    padding-right: 64px;
    border-right: solid 1px rgb(225, 225, 225);
  }

  .routedetail-view-table-column-right {
    padding: 10px;
    padding-bottom: 8px;
  }
}
.rp-details-spinner {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.table-row {
  background-color: #ffffff;

  .ant-table-cell {
    background-color: inherit;
  }

  &-blue {
    .ant-table-cell:first-child {
      text-align: center;
      border-left: 10px solid #2081c9;
    }
  }
  &-red {
    .ant-table-cell:first-child {
      text-align: center;
      border-left: 10px solid #ed1c24;
    }
  }
  &-green {
    .ant-table-cell:first-child {
      text-align: center;
      border-left: 10px solid #00ae4d;
    }
  }
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;