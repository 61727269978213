.revisit-reasons-container {
  display: flex;
  width: 100%;

  .radio-options-container {
    display: flex;
    width: 100%;
    margin-left: 2%;

    .radio-options-wrapper {
      display: flex;
      flex-direction: row;
    }
  }
}

@media screen and (max-width: 1018px) {
  .revisit-reasons-container {
    .radio-options-container {
      .radio-options-wrapper {
        flex-direction: column;
      }
    }
  }
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;