.noAccessIcon {
  height: 250px;
  margin-bottom: 60px;
  margin-top: 60px;
}

.access-request-container {
  text-align: center;
  position: relative;
  top: 100px;
}

.access-request-label {
  border-radius: 2px;
  line-height: 0;
  height: 75px;
  text-transform: none;
  transition: none;
  text-align: center;
  min-width: 200px;
  padding-top: 10px;
  margin-bottom: 20px;
}

.access-request-button:hover {
  background-color: #d44e00;
  color: #d9d9d9;
}

#access-request-subheader {
  margin-bottom: 0.5em;
  color: #222222;
  text-align: center;
  font-weight: 600;
}

.access-request-button {
  width: fit-content;
  margin: 10px 25px 10px 25px;
  border-radius: 2px;
  line-height: 0;
  align-content: center;
  min-width: 200px;
}

#mdoDisplay,
#occDisplay,
#nonHdDisplay,
#otherUserDisplay {
  text-align: justify;
  width: max-content;
  margin: 10px 25px 10px 25px;
}

#access-request-row {
  display: flex;
  justify-content: center;
}

.logout {
  width: calc(100% - 1500px);
  display: inline-block;
  justify-content: flex-end;
  #logout-link {
    cursor: pointer;
  }
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;