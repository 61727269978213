/* RevisitCalendar.less */
@import "~antd/dist/antd.less";

.revisit-calendar-section {
  display: flex;
  height: 100%;

  .revisit-info {
    margin-right: 15px;
  }

  .vertical-dividers {
    align-items: center;
  }

  .customer-detail {
    font-weight: bold;
    margin: 0;
  }

  .general-info {
    border: 1px solid lightgray;
    margin-top: 8%;
    padding: 10px;
    overflow-y: auto;
    height: 80%;
  }
}

/* Wrapper for the calendar element */
.calendar-wrapper-default {
  width: 300px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  border-radius: 4px;

  .ant-picker-cell-disabled.ant-picker-cell-in-view.ant-picker-cell-selected
    .ant-picker-cell-inner {
    background: transparent;
    color: @disabled-color;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: transparent;
    color: @text-color;

    &:hover {
      background: @picker-basic-cell-hover-color;
    }
  }
}

.calendar-wrapper {
  width: 300px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}

.calendar-container {
  display: flex;
  margin-right: 50px;
  margin-left: 50px;
  overflow-y: auto;
  padding-bottom: 0.5%;

  .calendar-heading {
    font-weight: bold;
    margin: 0;
  }

  .delivery-info-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 10px;

    .delivery-info {
      margin: 0;
      font-weight: bold;
      font-size: 12px;
    }
    .spinner-container {
      margin-left: 5px;
      font-weight: bold;
    }
  }

  .spinner-position {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .delivery-slots {
    width: 320px;
    background: #f7f7f7;
    border: 1px solid #e6e3e2;
  }

  .delivery-slots-error {
    width: 320px;
    background: transparent;
    border: 1px solid red;
    color: red;
  }

  .delivery-slots-content {
    display: flex;
    padding: 3%;
    align-items: center;
  }

  .slot-title {
    flex: 1;
    display: contents;
    align-items: center;
    justify-content: center;
    margin-right: 2%;

    h6 {
      margin: 0;
      padding: 0;
    }
  }

  .typography-title {
    margin: 0;
    padding: 0;
  }

  .slot-details {
    flex: 4;
    align-items: center;
    padding-left: 5%;

    p {
      margin: 0;
    }

    .highlighted-details {
      color: #1c955b;
      font-weight: bold;
    }
  }

  .slot-details-error {
    padding: 2%;
    p {
      margin: 0;
    }
  }

  .slot-details-error-button {
    margin-top: 5px;
    display: flex;
    justify-content: center;
  }
}

.calendar-spin-container {
  position: relative;
}

.spinner-position {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1018px) {
  .revisit-calendar-section {
    display: flex;
    height: 100%;
    flex-wrap: wrap;

    .calendar-comp {
      flex-basis: 100%;
    }

    .child {
      flex: 0 0 auto; /* This ensures the child elements do not grow or shrink */
      margin-top: 20px;
    }
  }

  .calendar-wrapper-default {
    margin-left: 0px;
    margin-top: 20px;
  }
}

@media screen and (max-width: 768px) {
  .revisit-calendar-section {
    flex-direction: column;
    align-items: center;

    .revisit-info {
      margin-right: 0;
    }
  }
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;