@import "../../App.less";

.customer-info-container {
  height: 100%;
  border-left: 1px solid #cccccc;
  padding: 0 0 0 15px;
  border-right: 1px solid #cccccc;
}

#order-id {
  font-weight: 400;
  font-size: 20px;
  color: #000000;
  margin-bottom: 10px;
  display: flex;
}

#customer-name {
  font-size: 17px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 12px;
}

.address {
  font-size: 14px;
  color: #494949;
  margin-bottom: 19px;
  text-transform: capitalize;

  div:nth-child(2) {
    margin-bottom: 3px;
  }
}

.contact-info {
  margin-bottom: 19px;
  font-size: 14px;
  color: #494949;

  #phone-number {
    margin-bottom: 3px;
    span {
      font-weight: 600;
    }
  }

  #alt-phone-number {
    margin-bottom: 3px;
    span {
      font-weight: 600;
    }
  }

  #email {
    font-size: 12px;
  }
}

#source {
  font-size: 14px;
  color: #494949;

  span {
    font-weight: 600;
  }
}

.edit-customer-info-icon {
  margin-left: 15px;
  cursor: pointer;
  svg {
    width: 16px;
    height: 16px;
    path {
      fill: #f96302;
    }
  }
}

@media (min-width: 769px) and (max-width: 1199px) {
  .customer-info-container {
    display: flex;
    justify-content: space-evenly;
    padding: 25px 0 0 0;
    border-left: none;
    border-bottom: 1px solid #cccccc;
  }
}

@media (max-width: 768px) {
  .customer-info-container {
    display: flex;
    justify-content: left;
    padding: 25px 0 0 0;
    border-left: none;
    border-bottom: 1px solid #cccccc;
    // margin: 0 18px 15px 18px;
    overflow: auto;
    white-space: nowrap;
  }

  .address {
    padding: 0 15px;
  }

  #source {
    padding-left: 15px;
  }
}

#customer_info_modal {
  .ant-modal {
    width: 90% !important;
  }
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;