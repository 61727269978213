.manifest-modal-header-label {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.manifest-center-container {
  padding: 0px 0px 5px 5px;
  gap: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .manifest-pdf-container {
    padding: 10px 5px 5px 5px;
    align-items: center;
    gap: 30px;

    .pdf-container-text {
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: center;
    }

    .pdf-container-button {
      width: 135px;
      height: 40px;
      padding: 8px 16px 8px 16px;
      gap: 8px;
      align-items: center;
    }
  }

  .manifest-csv-container {
    padding: 50px 5px 5px 5px;
    gap: 20px;
    align-items: center;

    .csv-container-text-heading {
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      text-align: center;
    }

    .csv-container-text {
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: center;
      margin: 0;
    }

    .csv-container-button {
      width: 135px;
      height: 40px;
      padding: 8px 16px 8px 16px;
      gap: 8px;
      align-items: center;
    }
  }
}

.manifest-spinner {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;