.error-spinner {
  left: 0;
  right: 0;
  text-align: center;
}

.notes-spinner {
  margin-top: 30px;
  left: 0;
  right: 0;
  text-align: center;
}

.notes-header {
  font-weight: 300;
  font-size: 16px;
  color: #494949;
  margin-bottom: 10px;

  .add-note {
    cursor: pointer;
  }
}

.retry-text {
  margin-left: 0.5em;
  color: #494949;
  font-size: 19px;
  font-weight: 300;
}

.button-container {
  width: 150px;
}

.retry-button {
  margin-top: 10px;
  width: 100%;
}

.error-container {
  text-align: center;
  padding-top: 1%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-add-notes {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: #f96302;
  margin-left: 20px;
}

.display-instruction {
  display: block;
  margin-left: 20px;
}

.view-all {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: #f96302;
  margin-left: 20px;
}

.add-note-text {
  padding-top: 100px;
  font-size: 18px;
}

.grayout {
  opacity: 0.6;
  cursor: default;
}

.cancel {
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  color: #f96302;
  margin-left: 25px;
}

.character-remaining-label {
  position: relative;
  bottom: 8px;
}

.note-footer {
  padding-bottom: 8px;
  padding-top: 8px;
}

button#submit-button {
  width: 25%;
  margin-left: 60px;
  font-size: large;
  height: 49px;
}

.notes-body {
  height: 200px;
  overflow-y: auto;
}

.notes-modal {
  max-width: 640px !important;
  width: 80% !important;

  .ant-modal-body [slot="body"] {
    padding: 0 1.5rem 1.5rem 1.5rem;
    .button-row {
      padding: 0;
      overflow: hidden;
      margin-bottom: 8px;
      .sort-button {
        float: right;
        font-size: 13px;
        padding-right: 12px;
      }
    }
  }

  div[slot="body"] > div {
    padding: 1rem 1.5rem;

    &:nth-child(2n) {
      background: #f5f5f5;
    }

    .note {
      margin: 0;
    }
  }

  .ant-modal-body {
    padding-top: 13px;
    max-height: 60vh !important;
    overflow-y: auto !important;
  }
}

@media (max-width: 991px) {
  .notes-container-dashboard-beta {
    border-left: none;
    // border-bottom: 1px solid #cccccc;
    height: 140px;
    padding: 15px 0;
  }

  .notes-container {
    border-left: none;
    border-bottom: 1px solid #cccccc;
    height: 140px;
    padding: 15px 0;
  }

  .notes-window-wrapper {
    height: 100px;
    overflow-y: auto;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .notes-container-dashboard-beta {
    padding-top: 15px;
    margin-top: 18px;
    height: 250px;
  }

  .notes-container {
    padding-top: 15px;
    margin-top: 18px;
    height: 250px;
  }

  .notes-window-wrapper {
    height: 200px;
    overflow-y: auto;
  }
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;