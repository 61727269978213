@import "../../../App.less";

#update-customer-info-header-text {
  padding-top: 10px;
  padding-left: 32px;
  padding-right: 32px;
  font-family: "Open Sans";
  color: black;
  font-weight: 500;
  font-size: 20px;
}

.customer-info-spinner {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
}

#update-customer-outer-delivery-address {
  min-height: 311px;
  width: 487px;
}

#update-customer-info-pointer-img {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

#cust-delivery-address-header,
#cust-current-delivery-date {
  font-weight: 500;
  color: rgb(151, 151, 151);
  font-size: 15px;
}

#delivery-address-text,
#cust-delivery-date {
  font-weight: 400;
  color: black;
  font-size: 15px;
  margin-left: 26px;
}
#cust-calender-img {
  margin-right: 5px;
  height: 20px;
  width: 20px;
}

#cust-state {
  width: 60px;
}

.update-customer-info-form {
  padding: 50px 50px 0 50px;
}

.update-customer-info-buttons {
  padding-top: 20px;
  button:hover {
    color: white;
    background-color: #f96300;
  }
}

@media only screen and (max-width: 767px) {
  .update-customer-info-buttons {
    #cancel-btn {
      width: 100%;
      margin-bottom: 20px;
    }
    #save-btn {
      width: 100%;
    }
  }
  #cust-delivery-address {
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 768px) {
  .update-customer-info-buttons {
    #cancel-btn {
      width: 150px;
    }
    #save-btn {
      width: 200px;
      float: right;
    }
  }
}

.cust-delivery-info {
  padding: 0 50px 0 50px;
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;