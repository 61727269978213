@import "~antd/dist/antd.less";

.ant-table-thead > tr > th {
  white-space: nowrap;
}

.ant-table-tbody > tr > th {
  white-space: pre;
}

.table-row-hued-red {
  background-color: #ff00000d;
}

.table-row-light {
  background-color: #ffffff;

  .ant-table-cell {
    background-color: inherit;
  }

  &-blue {
    .ant-table-row-expand-icon-cell {
      text-align: center;
      border-left: 10px solid #2081c9;
    }
  }
  &-yellow {
    .ant-table-row-expand-icon-cell {
      text-align: center;
      border-left: 10px solid #ffaa00;
    }
  }
  &-red {
    .ant-table-row-expand-icon-cell {
      text-align: center;
      border-left: 10px solid #ed1c24;
    }
  }
  &-green {
    .ant-table-row-expand-icon-cell {
      text-align: center;
      border-left: 10px solid #00ae4d;
    }
  }
  &-gray {
    .ant-table-row-expand-icon-cell {
      text-align: center;
      border-left: 10px solid #939393;
    }
  }
}

.table-row-dark {
  background-color: #f4f4f4;

  .ant-table-cell {
    background-color: inherit;
  }

  &-blue {
    .ant-table-row-expand-icon-cell {
      text-align: center;
      border-left: 10px solid #2081c9;
    }
  }
  &-yellow {
    .ant-table-row-expand-icon-cell {
      text-align: center;
      border-left: 10px solid #ffaa00;
    }
  }
  &-red {
    .ant-table-row-expand-icon-cell {
      text-align: center;
      border-left: 10px solid #ed1c24;
    }
  }
  &-green {
    .ant-table-row-expand-icon-cell {
      text-align: center;
      border-left: 10px solid #00ae4d;
    }
  }
  &-gray {
    .ant-table-row-expand-icon-cell {
      text-align: center;
      border-left: 10px solid #939393;
    }
  }
}

.customer-name-column {
  overflow: hidden;
  white-space: nowrap;
}

.clear-filter-button {
  float: left;
  width: fit-content;
  padding-bottom: 30px;
}

.ant-table-filter-trigger div div .ant-dropdown {
  z-index: 1 !important;
}

.ant-table-content {
  overflow: unset !important;
}
.table-expanded-loading {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}

.table-expanded-error-message {
  color: red;
  margin-left: 40px;
  font-weight: 600;
}

.order-work-bin-icon {
  display: flex;
  align-items: center;
  gap: 3px;
  svg {
    min-width: 14px;
    max-width: 14px;
    path {
      fill: #e72c4e;
    }
  }
}

.order-number {
  white-space: nowrap;
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;