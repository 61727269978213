body {
  background-color: #f4f4f4;
  font-family: "Open Sans", sans-serif;
}

#divider:hover {
  border-bottom: none !important;
  background-color: transparent;
}

.grid {
  margin: 0 40px 0 40px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
}

img {
  height: 50px;
}

.background {
  background-color: black;
  opacity: 0.18;
  height: 100vh;
}

.reset-link {
  color: #f96302;
  width: 50px;
}

.default-btn {
  &:hover {
    color: white;
    background-color: #f96302;
  }
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #666666 !important;
  box-shadow: none !important;
  color: black;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after {
  border-bottom: none;
  box-shadow: none !important;
  transition: none;
  border-color: #666666 !important;
}

.ant-input:focus {
  border-color: #ada3a3;
  box-shadow: 0 0 0 1px hsl(0deg 0% 63% / 20%);
}

.ant-message-notice {
  float: right;
  margin-right: 25px;
}

.ant-popover-message-title {
  width: 290px;
}

.no-access {
  font-size: 42px;
  color: #919191;
  padding-top: 400px;
  text-align: center;
}

#create-it-ticket-container {
  position: fixed;
  right: -56px;
  z-index: 99999990;
  bottom: 350px;
}

#create-it-ticket-button {
  font-family: "Open Sans", sans-serif;
  transform: rotate(90deg);
  white-space: nowrap;
  padding: 10px 10px;
  background-color: #f96302;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 0 0 3px 3px;
}
