.new-mo-container {
  .ant-btn {
    margin: 20px 20px 0 0;
    float: right;
  }
}

.modal-body-container {
  margin: 0 25px 0 25px;
  h3 {
    padding-bottom: 25px;
  }
  .cancel-button {
    float: right;
  }

  .notes {
    margin-bottom: 0;
  }
}

.steps-container {
  padding: 25px 0 50px 0;
}

.ant-steps-item-finish {
  .ant-steps-item-container {
    .ant-steps-item-icon {
      background-color: #0db500;
      border-color: #0db500;
      .ant-steps-icon {
        .anticon {
          color: white;
        }
      }
    }
  }
}

.steps-action {
  padding: 25px 0 25px 0;
}

.new-mo-calendar-container {
  padding: 0 0 100px 0;
  .spinner {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .slots-error {
    color: #919191;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &-message {
      font-size: 36px;
      margin-bottom: 0;
    }

    &-desc {
      font-size: 24px;
      font-weight: lighter;
    }
  }
  h4 {
    padding: 10px 0 0 20px;
  }
}

@media (max-width: 1199px) {
  .new-mo-container {
    .ant-btn {
      margin: 20px 0 0 0;
    }
  }

  .modal-body-container {
    margin: 0;
  }
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;