@import "~antd/dist/antd.less";

.reschedule-header {
  margin: 20px 0 0 32px;
  h3 {
    font-size: 20px;
    font-weight: 600;
  }
}

#reschedule-content {
  width: 80vw;
  padding: 76px 32px 0 32px;
}

#outer-delivery-date {
  height: 200px;
}

#calender-img {
  height: 16px;
  width: 16px;
}

#current-delivery-date {
  padding-left: 10px;
  color: black;
  font-size: 16px;
}

#delivery-date {
  padding-left: 26px;
  color: black;
  font-size: 16px;
}

#pointer-img {
  height: 16px;
  width: 16px;
}

#delivery-address-header {
  padding-left: 10px;
  color: #666666;
  size: 16px;
}

#delivery-address {
  padding-left: 26px;
  color: #666666;
  size: 16px;
}

#outer-delivery-address {
  height: 200px;
}

#delivery-calendar {
  padding-bottom: 50px;
}

#select-slots-box {
  margin-bottom: 62px;
  display: flex;
}

#selected-date {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f9f9f9;
  width: 66px;
  height: 60px;
  border: 1px#dddddd solid;
  color: #636363;
  font-size: 22px;
}

#green-text {
  color: #237c38;
}

#selected-date-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background: #f9f9f9;
  width: 268px;
  height: 60px;
  border: 1px#dddddd solid;
  padding-left: 20px;
  color: #636363;
  font-size: 12px;
}

#reschedule-dropdowns {
  font-size: 16px;
  margin-bottom: 30px;
  .label {
    font-weight: 600;
    font-size: 1rem;
  }
}
#reason-code {
  //width: 217px;
  .ant-select-selector {
    border: 1px solid #000000;
  }
  .ant-select-arrow {
    color: #f96302;
  }
}

#cust-contacted {
  //width: 275px;
  .ant-select-selector {
    border: 1px solid #000000;
  }
  .ant-select-arrow {
    color: #f96302;
  }
}

#add-notes {
  .label {
    font-weight: 600;
    font-size: 1rem;
  }
  font-size: 16px;
  padding-bottom: 100px;
  .ant-input {
    padding: 0.75rem 1rem;
    font-size: 1rem;
    border: solid 1px #000000;
    resize: none;
  }
}

#reschedule-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  padding-left: 30px;
  padding-right: 30px;
}

#cancel-button {
  width: 150px;
}
#save-button {
  justify-self: end;
  width: 200px;
}

.reschedule-calendar {
  width: 375px;
  box-shadow: rgb(230, 230, 230) 1px 0 0, rgb(230, 230, 230) -1px 0px 0px,
    rgb(230, 230, 230) 0 1px 0, rgb(230, 230, 230) 0 -1px 0,
    rgba(0, 0, 0, 0.2) 0 3px 13px;
  font-size: 17px;
  .ant-picker-panel {
    border-top: none;
  }

  .ant-picker-cell-disabled::before {
    background-color: transparent;
  }

  .ant-picker-cell-disabled {
    pointer-events: visible;
  }

  .ant-picker-content td {
    font-weight: 400;
    margin: 0;
    padding: 2px;
    height: 24px;
  }
  .ant-picker-content th {
    font-weight: bold !important;
  }

  .after {
    color: #8e8e8e;
    &.unavailable {
      color: #f22020;
      background-color: #eaeaea;
      &.selected {
        color: red;
        border: 1px solid #f22020;
      }
    }
  }

  .before {
    color: #8e8e8e;
    &.unavailable {
      color: #8e8e8e;
    }
  }

  .today {
    border: 1px solid #f96300;
    margin: 2px;
    &.unavailable {
      background-color: #eaeaea;
      color: #f22020;
    }
  }

  .available {
    color: black;
  }

  .selected {
    background-color: #f96300;
    color: white;
    border: 1px solid #f96300;
  }

  .month-and-year {
    font-weight: bold;
    text-align: center;
    font-size: 18px;
    padding-top: 1px;
    span {
      font-weight: 300;
      padding-left: 5px;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  #reschedule-content {
    width: 90vw;
    padding: 76px 32px 0 32px;
  }
}

@media (max-width: 991px) {
  .reschedule-header {
    text-align: center;
    margin: 20px 32px 0 32px;
  }

  .reschedule-container {
    overflow-y: scroll;
    height: 100vh;
  }
  #reschedule-content {
    width: 90vw;
    padding: 50px 32px 0 32px;
    overflow-y: scroll;
  }

  #outer-delivery-date {
    height: auto;
    padding: 0 0 50px 0;
    text-align: center;
  }

  #delivery-calendar {
    .ant-picker-calendar {
      margin: 0 auto;
    }
  }

  #outer-delivery-address {
    text-align: center;
    margin-left: -10px;
  }

  #select-slots-box {
    justify-content: center;
  }

  #reason-code {
    width: 100%;
  }
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;