@import "~antd/dist/antd.less";

.work-order-container {
  margin-bottom: 28px;

  #delivery-vendor {
    font-weight: 400;
    font-size: 12px;
    color: #6b6b6b;
  }
  #delivery-vendor-workbin {
    font-weight: 400;
    font-size: 12px;
    color: #6b6b6b;
  }
  .da-location-network {
    font-weight: 400;
    font-size: 12px;
    color: #6b6b6b;
    margin-bottom: 6px;
  }
  .da-location-agentName {
    font-weight: 600;
    font-size: 12px;
    color: #6b6b6b;
    margin-bottom: 6px;
  }

  .contact-phones {
    margin-bottom: 6px;
    font-size: 12px;
    color: #494949;
    #da-phone-number {
      margin-bottom: 3px;
      span {
        font-weight: 600;
      }
    }
  }

  .delivery-po-line-workbin {
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    margin-bottom: 3px;
  }

  .delivery-po-lines {
    margin-left: 7%;
    .delivery-po-line {
      font-weight: 400;
      font-size: 12px;
      color: #000000;
      margin-bottom: 3px;
    }
    .delivery-po-line-cancelled {
      font-weight: 400;
      font-size: 12px;
      color: #ff0000;
      margin-bottom: 3px;
    }
  }
  // Ant Design Collapse
  .work-order-collapse {
    &.ant-collapse {
      .ant-collapse-item {
        .ant-collapse-header {
          padding: 0 0 0 18px;

          .ant-collapse-arrow {
            left: 0;
            color: #6b6b6b;
          }
        }
      }
    }
    .wo-detail-loading {
      padding-left: 40%;
    }
  }
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;