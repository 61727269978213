.timeline-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 0;
}

.timeline-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 0;
}

.timeline-wrapper:not(:last-child):after {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 2.5px;
  height: 100%;
}

.timeline-container:last-child {
  content: "";
  height: 75px;
}

.timeline-item {
  text-align: left;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 38px;
  padding-left: 20px;
  width: 100%;
  height: 75px;
}

.timeline-resize-to-big {
  height: 95px;
}

.timeline-box {
  overflow-y: auto;
  padding-top: 18px;
  padding-left: 20px;
  width: 100%;
  height: 100%;
  max-height: 240px;
}

.timeline-item-content {
  font-size: 12px;
}

.line {
  content: "";
  position: absolute;
  bottom: 60px;
  left: 0.5px;
  width: 2px;
  height: 100%;
}

#firstLine {
  display: none;
}

#future-state-timeline {
  background-color: #d1d1d1;
}

#happy-path-Line {
  background-color: #9ed397;
}

#happy-path-Line-to-exception {
  background: #9ed397;
  background: linear-gradient(#9ed397, #da3832);
}

#exception-to-happy-path-Line {
  background: #ffaa00;
  background: linear-gradient(#da3832, #9ed397);
}

#exception-path-Line {
  background-color: #da3832;
}

.circle {
  background-color: white;
  border-radius: 60%;
  position: absolute;
  top: 5.5px;
  left: -7.8px;
  width: 20px;
  height: 20px;
  z-index: 1;
}

#happyBefore {
  background-color: #9ed397;
  border: 3px solid white;
  top: 9.5px;
}

#catchColor {
  background-color: #b5b5b5;
  border: 3px solid white;
  top: 9.5px;
}

#alternateBeforeOrderProcessed {
  background-color: #28a1d1;
  border: 3px solid white;
  top: 9.5px;
}

#alternateBefore {
  background-color: #9ed397;
  border: 3px solid white;
  top: 9.5px;
}

#happyAfter {
  top: 0px;
  width: 15px;
  height: 15px;
  left: -5.89px;
  background-color: white;
  border: 2px solid #d1d1d1;
}

#happyCurrent {
  left: -8.5px;
  top: 10px;
  background-color: #0db500;
  border: 8px solid #0db500;
}

#catchCurrent {
  left: -8.5px;
  top: 10px;
  background-color: #b5b5b5;
  border: 8px solid #b5b5b5;
}

#alternateCurrent {
  left: -8.5px;
  top: 10px;
  background-color: #28a1d1;
  border: 8px solid #28a1d1;
}

#current {
  left: -8.5px;
  top: 10px;
  background-color: #14b404;
  border: 8px solid #14b404;
}

.text {
  font-size: 12px;
}

#past-tense-event {
  color: #606060;
  size: 12px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
}

#past-tense-date-time {
  color: #8e8e8e;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
}

.present-tense-event {
  color: #0db500;
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.present-tense-event-order-or-rescheduled {
  color: #28a1d1;
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.present-tense-event-missed-delivery {
  color: #da3832;
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

//add class names for backgrpund

#present-tense-date-time {
  color: #666666;
  size: 13px;
  font-family: "Open Sans", sans-serif;
}

#present-tense-date-time-missed-delivery {
  color: #da3832;
  size: 13px;
  font-family: "Open Sans", sans-serif;
}

#future-state-event {
  color: #9e9e9e;
  size: 12px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.triangle {
  position: absolute;
  top: 0;
  left: -14.8px;
  width: 35px;
  height: 35px;
  z-index: 1;
  background-image: url("./icons/currentExc.svg");
}

.alternateTriangle {
  position: absolute;
  top: 0px;
  left: -14.8px;
  width: 35px;
  height: 35px;
  z-index: 1;
  background-image: url("./icons/pastExc.svg");
}

#newWarning {
  left: -15.8px;
  background-image: url("./icons/currentExc.svg");
}

#warning {
  left: -15.8px;
  background-image: url("./icons/pastExc.svg");
}

// Responsive View
@media (max-width: 1199px) {
  .timeline-box {
    width: fit-content;
    max-width: 100%;
    margin: 0 auto;
    padding-left: 0;
    max-height: inherit;
    overflow: auto;
    white-space: nowrap;
  }

  .line {
    top: 19px;
    bottom: 0;
    left: 0.5px;
    width: 100%;
    height: 2px;
  }

  #future-state-timeline {
    top: 8px;
    left: -53px;
  }

  #happy-path-Line {
    top: 9px;
    left: -72px;
  }

  #happyBefore {
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  #catchColor {
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  #alternateBeforeOrderProcessed {
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  #alternateBefore {
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  #happyAfter {
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  #happyCurrent {
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  #catchCurrent {
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  #alternateCurrent {
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  #warning {
    top: -7px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .timeline-wrapper {
    flex-direction: row;
    justify-content: left;
    padding: 0;
  }

  #past-tense-event {
    padding-top: 26px;
  }

  #happy-path-Line-to-exception {
    top: 9px;
    left: -77px;
  }

  #future-state-event {
    padding-top: 26px;
  }

  .present-tense-event {
    padding-top: 22px;
  }

  .present-tense-event-order-or-rescheduled {
    padding-top: 26px;
  }

  .present-tense-event-missed-delivery {
    padding-top: 26px;
  }
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;