.outer-row {
  display: flex;
  flex-flow: row wrap;
}

.order-delivery-details {
  margin: 22px 0 22px 0;
  position: relative;
  overflow: "hidden";
  display: flex;

  .merged-container {
    order: 0;
    display: flex;
    width: 25%;
    padding-bottom: 10px;
    margin-bottom: 1%;

    .divider-timeline {
      margin: 0;
      height: 100%;
    }

    .divider-delivery-info {
      margin: 0;
      height: 100%;
    }

    .time-window-container {
      width: 50%;
      padding-bottom: 3%;
    }

    .delivery-info-container {
      padding-left: 2%;
      width: 50%;
      padding-left: 2%;
    }
  }

  .merged-content-divider-container {
    display: none;
  }

  .tracker-info-container {
    display: flex;
    order: 1;
    width: 75%;
    margin-bottom: 1%;
    padding-bottom: 2%;
    justify-content: right;
  }

  .horizontal-top-divider-container {
    order: 2;
    padding-bottom: 1.5%;
    display: flex;
    width: 100%;
  }

  .horizontal-top-divider {
    margin: 0;
    width: 100%;
  }

  .work-order-container {
    padding-top: 1%;
    order: 3;
    width: 12%;
  }

  .work-order-divider-container {
    padding-bottom: 1.5%;
    order: 4;
  }

  .work-order-divider {
    margin: 0;
    height: 100%;
  }

  .customer-info-container-dashboard-beta {
    height: 100%;
    padding: 0 0 0 15px;
    order: 5;
    padding-right: 1%;
    width: 18%;
  }

  .customer-info-divider-container {
    padding-bottom: 1%;
    order: 6;
  }

  .customer-info-divider {
    margin: 0;
    height: 100%;
  }

  .product-details-container {
    order: 7;
    width: 40%;
    padding-right: 1%;
    padding-top: 3%;
  }

  .product-details-divider-container {
    padding-bottom: 1%;
    order: 8;
  }

  .product-details-divider {
    margin: 0;
    height: 100%;
  }

  .notes-container-dashboard-beta {
    order: 9;
    width: 28%;
    padding-left: 2%;
  }

  .product-details-divider-horizontal-container {
    order: 10;
    width: 100%;
  }

  .lower-horizontal-divider-container {
    display: none;
  }

  .actions-container {
    order: 11;
    width: 100%;
  }

  .revisit-pill-container {
    order: 2;
    margin-top: -20px;
    padding-bottom: 10px;
    display: flex;
    height: 100%;
  }

  .revisit-pill {
    border-radius: 25px;
    background-color: #286fad;
    color: #fff;
    font-size: 11px;
    padding: 0 15px 0 15px;
  }
}

.order-status-history-section {
  &-blue {
    border-top: 1px #2081c9 solid;
    border-left: 13px #2081c9 solid;
    border-bottom: 1px #2081c9 solid;
    border-right: 1px #2081c9 solid;
    padding: 20px;
    width: 100%;
  }

  &-yellow {
    border-top: 1px #ffaa00 solid;
    border-left: 13px #ffaa00 solid;
    border-bottom: 1px #ffaa00 solid;
    border-right: 1px #ffaa00 solid;
    padding: 20px;
  }

  &-red {
    border-top: 1px #ed1c24 solid;
    border-left: 13px #ed1c24 solid;
    border-bottom: 1px #ed1c24 solid;
    border-right: 1px #ed1c24 solid;
    padding: 20px;
  }

  &-green {
    border-top: 1px #00ae4d solid;
    border-left: 13px #00ae4d solid;
    border-bottom: 1px #00ae4d solid;
    border-right: 1px #00ae4d solid;
    padding: 20px;
  }

  &-gray {
    border-top: 1px #939393 solid;
    border-left: 13px #939393 solid;
    border-bottom: 1px #939393 solid;
    border-right: 1px #939393 solid;
    padding: 20px;
  }
}

.order-sections {
  max-height: 280px;
  overflow-y: auto;
}

.drawer-title {
  font-weight: bold;
}

.order-actions-wrapper {
  .order-actions {
    display: flex;
    justify-content: right;
    .ant-btn {
      width: 150px;
      margin: 10px;
    }
  }
}

.vertical-dividers {
  max-width: 20px !important;
  .ant-divider {
    height: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1475px) {
  .order-delivery-details {
    .merged-container {
      order: 0;
      display: flex;
      flex-direction: column;
      width: 22%;
      border-bottom: none;
      margin-bottom: 0;

      .divider-timeline {
        display: none;
      }
      .divider-delivery-info {
        display: none;
      }

      .time-window-container {
        border-right: none;
        width: 100%;
      }

      .delivery-info-container {
        width: 100%;
        border-right: none;
        padding-left: 0;
      }
    }

    .merged-content-divider-container {
      display: block;
      order: 1;
      padding-bottom: 1.5%;

      .merged-content-divider {
        height: 100%;
      }
    }

    .work-order-container {
      order: 2;
      width: 25%;
      padding-top: 0;
      padding-left: 2%;
    }

    .work-order-divider-container {
      order: 3;
    }

    .customer-info-container-dashboard-beta {
      order: 4;
      width: 25%;
      padding: 0;
      border-bottom: none;
      padding-left: 2%;
      padding-right: 2%;
    }

    .customer-info-divider-container {
      padding-bottom: 1%;
      order: 5;
    }

    .tracker-info-container {
      padding: 0 30px;
      display: flex;
      justify-content: center;
      order: 6;
      width: 22%;
      margin-bottom: 0;
      border-bottom: none;
    }

    .horizontal-top-divider-container {
      order: 7;
      margin: 0;
      width: 100%;
    }

    .product-details-container {
      order: 8;
      width: 100%;
      border-right: none;
      padding-top: 2%;
    }

    .product-details-divider-container {
      display: none;
    }

    .product-details-divider-horizontal-container {
      order: 9;
      display: block;
      width: 100%;
    }

    .product-details-horizontal-divider {
      width: 100%;
    }

    .notes-container-dashboard-beta {
      order: 10;
      width: 100%;
      margin-top: 2%;
    }

    .lower-horizontal-divider-container {
      order: 11;
      padding-bottom: 1.5%;
      display: flex;
      width: 100%;
    }

    .lower-horizontal-container {
      margin: 0;
      width: 100%;
    }

    .actions-container {
      display: flex;
      justify-content: center;
      order: 12;
    }

    .revisit-pill-container {
      order: 7;
    }
  }
}

@media (max-width: 767px) {
  .order-delivery-details {
    .merged-container {
      order: 0;
      display: flex;
      flex-direction: column;
      width: 47%;
      border-bottom: none;

      .time-window-container {
        width: 100%;
        border-right: none;
      }

      .delivery-info-container {
        width: 100%;
        border-right: none;
        padding-left: 0;
      }
    }

    .merged-content-divider-container {
      order: 1;
      display: block;
      padding-bottom: 1.5%;
    }

    .merged-content-divider {
      height: 100%;
    }

    .tracker-info-container {
      order: 2;
      width: 47%;
      display: flex;
      justify-content: center;
      border-bottom: none;
    }

    .work-order-container {
      order: 4;
      width: 47%;
      padding-top: 2%;
    }

    .work-order-divider {
      margin: 0 8px;
    }

    .customer-info-container-dashboard-beta {
      order: 5;
      width: 47%;
      padding: 0;
      border-bottom: none;
      border-right: none;
      padding-left: 2%;
      padding-right: 2%;
    }

    .customer-info-divider-container {
      display: none;
    }

    .product-detail-horizontal-top-divider-container {
      order: 6;
      width: 100%;
    }

    .product-detail-horizontal-top-divider {
      margin: 0;
    }

    .product-details-horizontal-divider {
      width: 100%;
      margin: 0;
    }

    .product-details-container {
      order: 7;
      width: 100%;
      border-right: none;
    }

    .product-details-divider-horizontal-container {
      order: 8;
    }

    .notes-container-dashboard-beta {
      order: 9;
      width: 100%;
    }

    .lower-horizontal-divider-container {
      width: 100%;
      display: block;
      order: 10;
    }

    .lower-horizontal-divider {
      margin: 0;
    }

    .actions-container {
      display: flex;
      justify-content: center;
      order: 11;
      border-top: none;
      width: 100%;
    }
  }
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;