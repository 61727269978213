.workbin-header {
  padding: 150px 25px 15px 30px;
  background-color: white;
  border-bottom: 1px solid #cccccc;
  h4 {
    font-weight: bold;
  }
}
.workbin-container {
  background-color: #f4f4f4;

  .workbin-table-container {
    position: relative;
    margin: 0 25px;
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    background-color: #e2e2e2;
    height: 100vh;
    .ant-table-thead > tr > th {
      color: #999999;
      background-color: white;
    }
    .ant-table-expanded-row {
      background-color: white;
    }
  }
  .table-row-light {
    background-color: white;
  }
  .table-row-dark {
    background-color: #f5f5f5;
  }
}

.workbin_loading {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}

.error_message {
  color: red;
  margin-left: 40px;
  font-weight: 600;
}

.no-result {
  font-size: 42px;
  color: #919191;
  padding-top: 150px;
  text-align: center;
}
.workbin-error-message {
  font-size: 42px;
  color: red;
  padding-top: 150px;
  text-align: center;
}
.workbin-spinner {
  padding-top: 150px;
  text-align: center;
}
.noDate {
  padding-left: 2.5rem;
}
@media (max-width: 968px) {
  .noDate {
    padding: 1.5rem;
  }
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;