.ant-btn-primary.animated-button-animating,
.ant-btn-primary.animated-button-animating:hover,
.ant-btn-primary.animated-button-animating:focus {
  background-color: green;
  border-color: green;
}

.ant-btn-primary.animated-button-animating::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 20px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: translate(-50%, -50%) rotate(45deg);
  opacity: 0;
  animation: drawCheckmark 0.5s ease-in-out forwards;
}

.animated-button-animating .anticon {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

@keyframes drawCheckmark {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
  }
  50% {
    width: 10px;
    height: 0;
    opacity: 1;
  }
  100% {
    width: 10px;
    height: 20px;
    opacity: 1;
  }
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;