@import "~antd/dist/antd.less";

.result-status {
  margin-bottom: 22px;
}

.delivery-details {
  margin: 22px 0 22px 0;
  position: relative;

  .order-info-container {
    border-left: 1px solid #cccccc;
    padding: 0 18px 0 18px;
    height: 100%;
    display: block;
  }

  .notes-container {
    padding: 0 18px 0 18px;
    border-left: 1px solid #cccccc;
    height: 300px;
  }

  .display-box-mo {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    border-radius: 4px;
    word-break: break-word;
    background-color: rgba(255, 255, 255, 0.92);
    padding: 25px 0;
    .mo-reason-container {
      padding: 0 18px;
    }

    .mo-line-items-container {
      padding: 0 18px;
      border-left: 1px solid #cccccc;
      height: 100%;
    }

    .mo-notes-container {
      padding: 0 18px 0;
      border-left: 1px solid #cccccc;
      height: 300px;
    }

    .mo-actions-container {
      padding: 0 18px;
      border-left: 1px solid #cccccc;
      height: 100%;
    }
  }

  .display-box-select-date {
    width: 100%;
    border-radius: 4px;
    word-break: break-word;
    background-color: white;
    padding: 25px 0;
  }

  .display-box {
    width: 100%;
    border-radius: 4px;
    word-break: break-word;
    background-color: white;
    padding: 25px 0 25px 0;

    &.blur {
      filter: blur(8px);
      -webkit-filter: blur(8px);
    }
  }

  .order-module {
    position: relative;
    z-index: 0;
    height: 300px;
    overflow-y: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.status-history-section {
  &-blue {
    border-top: 1px #2081c9 solid;
    border-left: 13px #2081c9 solid;
    border-bottom: 1px #2081c9 solid;
    border-right: 1px #2081c9 solid;
  }

  &-yellow {
    border-top: 1px #ffaa00 solid;
    border-left: 13px #ffaa00 solid;
    border-bottom: 1px #ffaa00 solid;
    border-right: 1px #ffaa00 solid;
  }

  &-red {
    border-top: 1px #ed1c24 solid;
    border-left: 13px #ed1c24 solid;
    border-bottom: 1px #ed1c24 solid;
    border-right: 1px #ed1c24 solid;
  }

  &-green {
    border-top: 1px #00ae4d solid;
    border-left: 13px #00ae4d solid;
    border-bottom: 1px #00ae4d solid;
    border-right: 1px #00ae4d solid;
  }

  &-gray {
    border-top: 1px #939393 solid;
    border-left: 13px #939393 solid;
    border-bottom: 1px #939393 solid;
    border-right: 1px #939393 solid;
  }
}

#revisit-pill {
  text-align: center;
  border-radius: 25px;
  background-color: #286fad;
  height: 16px;
  margin-bottom: 20px;
  width: 100%;
}

#revisit-static-text {
  font-weight: bold;
  font-size: 8px;
  color: white;
  position: relative;
  top: -5px;
}

#revisit-type-text {
  font-weight: 600;
  font-size: 8px;
  color: white;
  position: relative;
  top: -5px;
}

#pill-boundary {
  height: 30px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .delivery-details {
    .order-info-container {
      border-left: none;
      margin-top: 18px;
      padding-top: 15px;
    }

    .notes-container {
      padding-top: 15px;
      margin-top: 18px;
      height: 250px;
    }

    .order-module {
      height: 200px;
    }

    .display-box-mo {
      .mo-reason-container {
        padding: 0;
        margin: 0 18px;
        border-bottom: 1px solid #cccccc;
      }

      .mo-line-items-container {
        border-left: none;
        margin-top: 18px;
      }

      .mo-notes-container {
        margin-top: 18px;
        border-left: 1px solid #cccccc;
        height: 286px;
      }

      .mo-calendar-container {
        height: 375px;
      }

      .mo-actions-container {
        margin-top: 18px;
        max-height: 286px;
      }
    }
  }
}

@media (max-width: 991px) {
  .delivery-details {
    .order-info-container {
      height: 135px;
      border-left: none;
      border-bottom: 1px solid #cccccc;
      margin: 0 18px;
      padding-top: 15px;
    }

    .notes-container {
      height: 140px;
      border-left: none;
      border-bottom: 1px solid rgb(204, 204, 204);
      margin: 0px 18px;
      padding: 15px 0px;
    }

    .order-module {
      display: flex;
      justify-content: space-evenly;
      height: 100px;
      border-left: none;
    }

    .display-box-mo {
      .mo-reason-container {
        padding: 0;
        margin: 0 18px;
        border-bottom: 1px solid #cccccc;
      }

      .mo-line-items-container {
        border-left: none;
        border-bottom: 1px solid #cccccc;
        margin: 18px 18px 0 18px;
        height: 205px;
      }

      .mo-notes-container {
        margin: 18px 18px 0 18px;
        border-left: none;
        border-bottom: 1px solid #cccccc;
        height: 250px;
      }

      .mo-actions-container {
        border-left: none;
        padding: 15px 0 10px;
        margin: 18px 18px 5px 18px;
        display: flex;
        justify-content: space-evenly;
      }
    }
  }
}

.status-history-section-mo {
  height: 95%;
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;