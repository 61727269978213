.calendar {
  box-sizing: border-box;
  font-size: 1rem;
  padding-left: 20px;
  .header {
    background-color: white;
    text-align: center;
    min-height: 2rem;
    line-height: 2rem;
    color: #333333;
    font-weight: 500;
    display: flex;
    justify-content: center;
    font-size: 20px;
    .previous {
      text-align: left;
      margin: 1rem;
      .left-arrow {
        font-size: 20px;
      }
    }
    .available-week {
      margin: 1rem;
      font-size: 17px;
    }
    .next {
      text-align: right;
      margin: 1rem;
      .right-arrow {
        font-size: 20px;
      }
    }
  }
  .calendar-body-wrapper {
    .body {
      box-shadow: 1px 10px 14px rgba(0, 0, 0, 15%);
      padding: 0 10px;
    }
  }

  .day-names {
    display: flex;
    flex-wrap: wrap;
    max-width: 1000px;
    margin: 0 auto;
    align-items: center;
  }

  .day {
    position: relative;
    width: calc(100% / 7);
    height: 44px;
    display: inline-block;
    background-color: white;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    z-index: 1;
    text-align: center;
  }

  .week {
    background-color: white;
    width: calc(100% / 7);
    height: 44px;
    line-height: 44px;
    text-align: center;
    text-transform: uppercase;
    color: #333333;
    font-weight: 400;
    div {
      width: 100%;
    }
  }
}

.ant-btn-link {
  color: #8e8e8e;
  font-weight: bold;
}

.calendar .day {
  position: relative;
  color: #333333;
  .day-of-the-week {
    font-size: 14px;
    font-weight: bold;
  }
  .month {
    font-size: 14px;
    background-color: transparent;
  }
  .date {
    background-color: transparent;
    font-size: 22px;
    font-weight: bold;
    padding: 10px 0;
    overflow: hidden;
  }

  .availability {
    background-color: transparent;
    font-size: 14px;
    font-weight: bold;
    overflow: hidden;
  }
  .availability.zero {
    color: #333333;
  }
  .availability.nonZero {
    color: #f96302;
  }
  .availability.unAvailable {
    color: #999999;
  }
}
.calendar .day div.pointer {
  cursor: pointer;
}
.calendar .day div.before {
  background-color: #eaeaea;
  color: #333333;
  margin: 3px;
}
.calendar .day div.selected {
  background-color: #ffffff;
  color: #333333;
  margin: 3px;
  border: 2px solid #f96302 !important;
}
.calendar .day div.today {
  background-color: #caf4ff;
  margin: 3px;
  border: none;
}
.calendar .day div.available {
  border: 1px solid #eaeaea;
  margin: 3px;
}
.calendar .day div.today.available {
  border: none;
}

.spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .calendar {
    padding: 0;
    .calendar-body-wrapper {
      overflow: auto;
      white-space: nowrap;
      .body {
        width: 750px;
      }
    }
  }
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;