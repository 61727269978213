.revisit-drawer-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .revisit-drawer-content {
    height: 100%;

    .revisit-main-content {
      height: 70%;
      display: flex;
    }

    .revisit-item-selection {
      width: 70%;
    }

    .revisit-notes {
      width: 30%;
    }
  }
}

.revisit-footer {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.drawer-subtitle {
  font-size: medium;
  font-weight: 600;
}

.drawer-subtitle-padding {
  margin-bottom: 50px;
}

.revisit-actions-wrapper {
  .revisit-actions {
    display: flex;
    justify-content: right;
    .ant-btn {
      width: 150px;
      margin: 10px;
    }
  }
}

.custom-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  background-color: #fff;
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: #2196f3;
}

.checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark::after {
  display: block;
}
.custom-checkbox .label {
  margin-left: 0.5rem;
}

@media screen and (max-width: 1018px) {
  .revisit-drawer-container {
    .revisit-drawer-content {
      .revisit-notes {
        width: 100%;
      }
      .revisit-item-selection {
        width: 100%;
      }
    }

    .revisit-main-content-direction {
      flex-direction: row;
    }
    .revisit-item-selection {
      width: 100%;
    }
    .revisit-notes {
      width: 100%;
    }
    .revisit-footer {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 768px) {
  .revisit-drawer-container {
    .revisit-main-content-direction {
      flex-direction: column;
    }
  }
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;