.customer-info-card {
  .customer-info-edit-icon {
    padding: 0px;
    width: 30px;
    background-color: transparent;
    border: none;
    font-size: 31px;
    align-items: center;
    justify-content: center;
    height: 30px;
    box-shadow: 2px 2px 2px #dddcdc;
    border-radius: 50%;
    svg {
      position: relative;
      width: 15px;
      height: 15px;
      right: -1px;
      top: -12px;
      path {
        fill: #f96302;
      }
    }
  }

  .customer-details-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    .customer-pro-name {
      display: flex;
      align-items: center;
    }
  }

  .customer-pro-label {
    display: flex;
    align-items: center;
    span {
      font-weight: 700;
      text-decoration: underline;
      color: #f96302;
      text-decoration-thickness: 2px;
    }
  }

  .customer-address,
  .customer-name {
    font-size: 16px;
    font-weight: 700;
  }

  .customer-phone-number {
    margin-top: 10px;
    font-size: 12px;

    .customer-phone-type {
      font-weight: 600;
    }
  }
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;