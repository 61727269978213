.work-order-card {
  .ant-btn {
    width: auto;
    position: relative;
    left: -15px;
  }

  .order-number {
    display: inline-block;
    font-size: 18px;
    margin-bottom: 5px;
  }

  .work-order-number {
    display: inline-block;
    font-size: 18px;
    margin-bottom: 5px;
  }

  .work-order-text {
    font-size: 12px;
  }

  .work-order-count {
    font-size: 12px;
  }

  .work-order-pallet-count {
    font-size: 12px;
  }

  .work-order-source {
    display: inline-block;
    font-size: 14px;
    margin-top: 10px;
  }
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;