// Container for All PO
.po-container {
  margin-bottom: 28px;
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
}

// Contains PO Content
.po-content-container {
  background-color: #f2f2f2 !important;
  .ant-divider-horizontal {
    margin: 0;
  }
  // Ant Card Header
  .ant-card-head {
    min-height: inherit;
    padding: 0 18px;
    .ant-card-head-wrapper {
      .ant-card-head-title {
        padding: 5px 0;
        .po-number-title {
          white-space: pre-line;
          .po-number-text {
            font-size: 10px;
            color: #6b6b6b;
          }
          .po-number {
            font-size: 12px;
            color: black;
            max-width: 200px;
          }
        }
      }
    }
  }
  // Ant Card Body
  .ant-card-body {
    padding: 0;
    .product-line-container {
      #product-line {
        padding: 5px 18px;
        .model-number-label {
          font-size: 10px;
          color: #6b6b6b;
        }
        .label-offset {
          margin-top: 12px;
        }
        .model-number {
          font-size: 12px;
          color: #000000;
        }
        .description-label {
          font-size: 10px;
          color: #6b6b6b;
        }
        .description {
          font-size: 12px;
          font-weight: bold;
          color: #000000;
          white-space: initial;
        }
        .sku-number-label {
          font-size: 10px;
          color: #6b6b6b;
          margin-top: 12px;
        }
        .sku-number {
          font-size: 12px;
          color: #000000;
        }
        .line-cancelled-label {
          color: #828282;
          font-size: 11px;
          margin-top: 8px;
        }
      }
      // PO Cancelled Content
      #cancelled-item-container {
        background-color: #f4eded;
        padding: 5px 18px;
        #cancelled-item-label {
          color: #8c6c6c;
          font-size: 12px;
        }
        #cancelled-product-line-content {
          border-left: 3px solid #c6baba;
          padding-left: 15px;
          margin: 10px 0;
          .cancelled-description-label {
            font-size: 10px;
            color: #6b6b6b;
          }
          .cancelled-description {
            font-size: 12px;
            color: #000000;
            margin-bottom: 3px;
            white-space: initial;
          }
          .cancelled-model-number-label {
            font-size: 10px;
            color: #6b6b6b;
          }
          .label-offset {
            margin-top: 12px;
          }
          .cancelled-model-number {
            font-size: 12px;
            color: #000000;
            margin-bottom: 3px;
          }
          .cancelled-sku-number-label {
            font-size: 10px;
            color: #6b6b6b;
            margin-top: 12px;
          }
          .cancelled-sku-number {
            font-size: 12px;
            color: #000000;
          }
        }
      }
    }
  }
}

// Ant Design Collapse
.po-collapse {
  &.ant-collapse {
    .ant-collapse-item {
      .ant-collapse-header {
        padding: 0 0 0 18px;
        .ant-collapse-arrow {
          left: 0;
          color: #6b6b6b;
        }
      }
    }
  }
  // Panel Headers
  .vendor-ms-number-container {
    .vendor-short-name {
      font-size: 12px;
      color: #6b6b6b;
    }
    .ms-number {
      color: black;
      font-weight: 500;
    }
    .vendor-short-name-cancelled {
      font-size: 12px;
      color: #6b6b6b;
    }
  }
  // PO Cancelled Container
  .po-cancelled-container {
    #cancelled-img {
      height: 14px;
      width: 14px;
      margin-right: 5px;
    }
    .po-cancelled {
      font-size: 11px;
      color: #e01919;
      padding: 0 0 0 3px;
    }
  }

  .po-detail-loading {
    padding-left: 40%;
  }
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;