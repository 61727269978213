@import "~antd/dist/antd.less";

// Header
.header-container {
  width: 100%;
  position: fixed;
  z-index: 4;
  .home-depot-logo {
    width: 42px;
  }
  .utility-bar {
    justify-content: center;
    background-color: black;
    display: flex;
    color: white;
    padding: 8px 21px;
    font-size: 16px;
    .ant-menu-item {
      padding: 0 20px;
    }
    .utility-left {
      width: 1500px;
      display: flex;
      align-items: center;
      .user {
        color: #696969;
        span {
          color: white;
        }
      }
      .userWM {
        display: none;
      }
      .location {
        padding-left: 60px;
        a {
          color: #696969;
          span {
            color: white;
          }
        }
      }
    }
    .logout {
      width: calc(100% - 1500px);
      display: flex;
      justify-content: flex-end;
      align-items: center;
      #logout-link {
        cursor: pointer;
      }
    }
  }

  #lbd-fdc-icon {
    height: 28px;
    margin-top: 18px;
  }

  #dashboard-sth-icon {
    height: 28px;
    margin-top: 18px;
  }

  #routeplanner-view-icon {
    height: 28px;
    margin-top: 18px;
  }

  #appliance-icon {
    height: 22px;
    margin-top: 20px;
  }
  #mail-icon {
    height: 22px;
    margin-top: 20px;
  }
  #mars-icon {
    height: 24px;
    margin-top: 18px;
  }
  #data-maintenance-icon {
    height: 24px;
    margin-top: 18px;
  }
  #emt-icon {
    height: 24px;
    margin-top: 18px;
  }
  #work-bin-icon {
    height: 22px;
    margin-top: 20px;
  }
  #dashboard-beta-text {
    line-height: 20px;
  }
  .header-icons {
    display: block;
    margin: -6px auto;
    &.white-icon {
      fill: white;
    }
    &.gray-icon {
      fill: gray;
    }
  }

  .ant-menu-horizontal {
    height: 73px;
    line-height: 26px;
    .ant-menu-item-disabled {
      padding: 0 !important;
      cursor: inherit;
      margin: 0 10px !important;
    }
    .ant-menu-item {
      top: 0;
      a {
        &:hover {
          color: white;
        }
      }
      span {
        font-weight: 300;
        margin: 0;
      }
      .ant-menu-item-icon {
        margin: 0 auto;
      }
    }
    &:not(.ant-menu-dark) {
      .ant-menu-item {
        margin: 0;
        padding: 0 12px 0 12px;
        &::after {
          border-bottom: none;
        }
        .menu-divider {
          height: 76px;
          width: 8px;
          background-color: #222;
        }
        &:hover {
          color: white;
          background: #555555;
          border-bottom: 4px solid #f96302;
        }
      }
      .ant-menu-item-selected {
        color: white;
        border-bottom: 4px solid #f96302;
        background: black;
        &:hover {
          background: black;
        }
      }
    }
  }

  .menu-bar {
    padding: 0 12px 0 12px;
    overflow: auto;
    background: @menu-bg;
    display: flex;
    .app-name {
      h1 {
        color: white;
        padding: 23px 0 0 0;
        font-weight: 100;
        font-size: 20px;
      }
    }
    .app-info {
      display: flex;
      width: 368px;
    }
    .logo {
      width: 60px;
      a {
        display: inline-block;
        font-size: 20px;
        padding: 12px 20px 0 0;
        text-transform: capitalize;
      }
    }
    .menu-links {
      width: calc(100% - 364px);
      display: flex;
      justify-content: flex-end;
      &.ant-menu-overflow {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
      &.ant-menu-item {
        padding: 0 5px;
        font-weight: 100;
      }
      &.ant-menu-submenu-title {
        padding: 10px 20px;
      }
      .ant-menu-submenu-title {
        a {
          padding: 10px 15px;
        }
      }
    }
  }
  .ant-menu-horizontal {
    border-bottom: none;
    width: 100%;
    display: flex;
    justify-content: end;
  }
  .ant-menu-horizontal > .ant-menu-item {
    border-bottom: 4px solid transparent;
  }
  .barsMenu {
    display: none;
  }
}

.menu-drawer {
  display: none;
}

// Tablet and  Mobile View
@media (max-width: 807px) {
  .header-container {
    .menu-bar {
      .menu-links {
        #menu-horizontal {
          display: none;
        }
        .barsMenu {
          float: right;
          height: 32px;
          padding: 6px;
          display: inline-block;
          margin-top: 20px;
          background: none;
          border-color: white;
        }
        .barsBtn {
          display: block;
          width: 20px;
          height: 2px;
          background: white;
          position: relative;
        }
        .barsBtn:after,
        .barsBtn:before {
          content: attr(x);
          width: 20px;
          position: absolute;
          top: -6px;
          left: 0;
          height: 2px;
          background: white;
        }
        .barsBtn:after {
          top: auto;
          bottom: -6px;
        }
        .ant-drawer-right.ant-drawer-open {
          display: inherit;
        }
      }
    }
  }

  .menu-drawer {
    display: inherit;

    .ant-drawer-header {
      background: @menu-bg;
      border-bottom: none;
      .ant-drawer-title {
        color: white;
        font-weight: bold;
        text-align: center;
      }
      .ant-drawer-close {
        color: white;
      }
    }
    .ant-drawer-body {
      padding: 0;
    }
    .ant-menu-inline {
      border-right: none;
      .ant-menu-item {
        text-align: center;
        color: white;
        padding-right: 40px;
      }
      .ant-menu-item::after {
        border-right: none;
      }
    }
    .ant-menu:not(.ant-menu-horizontal) {
      .ant-menu-item-selected {
        background-color: black;
        border-left: 3px solid @primary-color;
      }
    }
    .header-icons {
      display: inline;
      margin: 5px 10px -4px 0;
      height: 20px;
      &.white-icon {
        fill: white;
      }
      &.gray-icon {
        fill: gray;
      }
    }
    .ant-drawer-body {
      background: @menu-bg;
      padding: 0;
    }
    .right-menu {
      display: none;
    }
  }
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;