.delivery-window-container {
  #change-delivery-label {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
    font-family: "Open Sans", semibold;
  }
  .inline-window {
    width: 100%;
    text-align: left;
    margin-bottom: 15px;
    div {
      display: flex;
      font-size: 14px;
      margin-bottom: 5px;
      .time-input {
        height: 42px;
        width: 66%;
        font-size: 16px;
        padding: 12px 16px 12px 16px;
        border-radius: 0;
        border: 1px solid #666666;
      }
      .time-dropdown {
        height: 42px;
        border-radius: 0;
        border: 1px solid #666666;
        width: 88px;
        .ant-select-arrow {
          top: 47%;
          width: 20px;
          font-size: 16px;
          color: #f96302;
        }
      }
      .start-time-div {
        width: 75px;
      }
      .end-time-div {
        margin-top: 10px;
        width: 50px;
        font-size: 12px;
      }
      #start-time-start-time {
        width: 55%;
      }
      #start-time-drop-down {
        width: 45%;
      }
    }
  }
  .ant-btn {
    width: 75px !important;
  }
  #save-button {
    margin-right: 10px;
  }
}

@media (max-width: 1199px) {
  .start-time-div,
  .end-time-div {
    width: 20rem;
  }
}
@media (max-width: 375px) {
  .start-time-div,
  .end-time-div {
    width: 100%;
  }
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;