// Dater Filter Input Container
#date-filter-container {
  margin-top: 30px;
  .view-date {
    color: #494949;
    font-size: 12px;
  }
  .selected-date {
    padding-left: 10px;
    color: #494949;
    .ant-select-arrow {
      width: 20px;
      font-size: 16px;
      top: 47%;
      color: #f96302;
    }
  }
  .anticon-down {
    font-size: 16px;
    position: absolute;
    padding: 3px 0 0 5px;
    color: #f96302;
  }
}

.date-filter-dropdown {
  position: fixed;
  top: 11.5rem !important;
  .ant-dropdown-menu-title-content {
    width: 100%;
  }
}

.location-dropdown {
  min-width: min-content;
  position: fixed;
  top: 2.2rem !important;
}

// Date Filter Dropdown
#date-filter-dropdown {
  position: fixed;
  padding-top: 1rem !important;
  .date-dropdown-menu {
    background-color: white;
    width: 245px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  .location-dropdown-menu {
    height: 120px;
    border-bottom: 1px solid #d9d9d9;
    p {
      font-size: 12px;
      color: #212121;
      margin: 0;
      line-height: 25px;
    }
    .location-label {
      font-size: 12px;
      color: #8e8e8e;
      margin-top: 5px;
    }
  }
  .radio-group-menu {
    height: 100px;
  }
  .radio-group {
    padding: 10px 0 15px 0;
    .ant-radio-wrapper {
      display: block;
      height: 40px;
      font-size: 16px;
      font-weight: 500;
    }
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
  }

  .date-picker-container {
    padding: 10px;
    height: 115px;
    .date-picker-label {
      color: #444444;
      font-size: 16px;
      font-weight: 600;
      height: 33px;
    }
    .ant-picker-focused {
      box-shadow: none;
    }
  }
  .ant-picker-input {
    input {
      &:placeholder-shown {
        font-size: 16px;
      }
    }
    .ant-picker-suffix {
      margin: 4px 4px 4px 0;
    }
  }
  #calendar-date {
    width: 24px;
    height: 24px;
  }
}

// Date Picker Calendar
.date-picker-calendar {
  .ant-picker-date-panel .ant-picker-content {
    width: 300px;
  }
  .ant-picker-date-panel {
    width: 325px;
  }
  .ant-picker-prev-icon::before,
  .ant-picker-next-icon::before {
    width: 10px;
    height: 10px;
    font-weight: 800;
  }
  .ant-picker-cell {
    padding: 4px 0;
  }
  .ant-picker-prev-icon,
  .ant-picker-super-prev-icon {
    color: #f96300;
  }
  .ant-picker-next-icon,
  .ant-picker-super-next-icon {
    color: #f96300;
  }
  .ant-picker-header-view button {
    font-weight: bolder;
  }
  .ant-picker-header-next-btn {
    font-weight: bolder;
  }
  .ant-picker-header-view button:not(:first-child) {
    font-weight: 300;
  }
  .ant-picker-footer {
    display: none;
  }
  .ant-picker-cell .ant-picker-cell-inner {
    position: relative;
    z-index: 2;
    display: inline-block;
    width: 31px;
    height: 28px;
    line-height: 28px;
    border-radius: 2px;
    transition: background 0.3s, border 0.3s;
  }
  .ant-picker-content th,
  .ant-picker-content td {
    font-weight: 500;
  }
  .ant-picker-header-super-prev-btn {
    display: none;
  }
  .ant-picker-header-super-next-btn {
    display: none;
  }

  .ant-picker-header {
    border-bottom: none;
    button {
      &:hover {
        background-color: rgb(229, 229, 229);
      }
    }
  }
}

// Date Picker Input
.date-picker-input {
  &.ant-picker {
    width: 100%;
    border: 1px solid #6b6b6b;
    padding: 2px 5px 2px 10px;
  }
  .date-calendar {
    fill: #f96300;
  }
  &.ant-picker-disabled {
    border: 1px solid #cccccc;
    padding: 2px 5px 2px 10px;
    .date-calendar {
      fill: #cccccc;
    }
  }
}

// Disabled Date Picker Input
//.disabled-date-picker {
//  &.ant-picker {
//    width: 100%;
//    border: 1px solid #CCCCCC;
//    padding: 2px 5px 2px 10px;
//  }
//
//}

.location-selector {
  border: 1px solid #000000;
  border-radius: 2px;
  .ant-select-arrow {
    color: #f96300;
  }
}

// Locations Selector in Dropdown
.locations {
  .ant-select-item-option-content {
    overflow: inherit;
    white-space: inherit;
    text-overflow: inherit;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #f5f5f5;
  }
}

@media (max-width: 1199px) {
  #date-filter-dropdown {
    position: fixed;
    padding-top: 19rem;
  }
  .date-filter-dropdown {
    position: fixed;
    padding-top: 3.25rem;
  }
  .location-dropdown {
    min-width: auto !important;
    position: fixed;
    top: 2.2rem !important;
  }
}

@font-family: Open Sans,sans-serif;@primary-color: #F96302;@menu-bg: #333333;@menu-item-color: #ffffff;@body-background: #f4f4f4;@heading-color: #222222;@text-color: #222222;@text-color-secondary: #767676;@border-color-base: #d9d9d9;@outline-width: 1px;@outline-color: #A1A1A1;@btn-font-weight: 600;@btn-text-shadow: none;@btn-disable-bg: #d9d9d9;@btn-disable-border: #d9d9d9;@border-color-split: #d9d9d9;@btn-primary-bg: #f96302;@btn-default-color: #f96302;@btn-default-border: #f96302;@btn-border-width: 2px;@animation-duration-slow: 0.2s;@animation-duration-base: 0.08s;@animation-duration-fast: 0.05s;@pagination-font-family: "Open Sans", sans-serif;@label-color: #333333;@input-hover-border-color: #A1A1A1;@input-number-handler-hover-bg: #A1A1A1;@layout-body-background: #eceff1;@layout-header-padding: 0 24px;